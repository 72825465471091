<template>
    <div class="card-body text-center">
        <!-- Image -->
        <img
            src="/img/illustrations/scale.svg"
            class="img-fluid"
            style="max-width: 182px;"
        />
        <h1>{{ title }}</h1>
        <p class="text-muted" v-if="subtitle">
            {{ subtitle }}
        </p>
        <slot name="button"></slot>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
        },
    },
};
</script>
