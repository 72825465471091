<template>
    <div v-if="getActiveLocation">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">
                                {{ $t("locations.location") }}
                            </h6>
                            <h1 class="header-title">
                                {{ getActiveLocation.Name }}
                            </h1>
                        </div>
                        <div
                            class="col col-md-auto mt-3 mt-md-0"
                            v-if="$can('manage', getActiveLocation) && !roleFlag"
                        >
                            <b-dropdown
                                :text="$t('buttons.manage')"
                                right
                                variant="primary"
                                toggle-tag="a"
                                class="float-right"
                            >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="editLocation(getActiveLocation)"
                                    >{{ $t("buttons.edit") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deleteLocation(getActiveLocation)"
                                    >{{ $t("buttons.delete") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="locationImage(getActiveLocation)"
                                    >{{
                                        $t("locations.image")
                                    }}</b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                        <div
                            class="col col-md-auto mt-3 mt-md-0"
                            v-else-if="(getActiveUserRole == 'CompanyOwner') || (this.getActiveUserRole == 'CompanyManager')"
                        >
                            <b-dropdown
                                :text="$t('buttons.manage')"
                                right
                                variant="primary"
                                toggle-tag="a"
                                class="float-right"
                            >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="editLocation(getActiveLocation)"
                                    >{{ $t("buttons.edit") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deleteLocation(getActiveLocation)"
                                    >{{ $t("buttons.delete") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="locationImage(getActiveLocation)"
                                    >{{
                                        $t("locations.image")
                                    }}</b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row" v-if="!admin && !roleFlag">
                <div
                    class="col-12 col-lg-6 col-xl"
                    v-if="$can('viewUsers', getActiveLocation)"
                >
                    <!-- Hours -->
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'members',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <!-- Title -->
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("users.users") }}
                                        </h6>

                                        <!-- Heading -->

                                        <!-- <span class="h2 mb-0">{{
                                            getActiveLocation.UsersCount  ||
                                            0
                                        }}</span> -->
                                        <span class="h2 mb-0">{{
                                            locationUsersList.length  ||
                                            0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <!-- Icon -->
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-user
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                        </b-link>
                    </div>
                </div>

                <div
                    class="col-12 col-lg-6 col-xl"
                    v-if="$can('viewDoors', getActiveLocation)"
                >
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'doors',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("doors.doors") }}
                                        </h6>
                                        <span class="h2 mb-0">{{
                                            getActiveLocation.DevicesCount || 0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-door
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </b-link>
                    </div>
                </div>

                <div
                    class="col-12 col-lg-6 col-xl"
                    v-if="$can('viewKeys', getActiveLocation)"
                >
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'keys',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("keys.keys") }}
                                        </h6>
                                        <span class="h2 mb-0">{{
                                            getActiveLocation.KeysCount || 0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-key
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </b-link>
                    </div>
                </div>
            </div>
            <div class="row" v-else-if="!admin && roleFlag">
                <div
                    class="col-12 col-lg-6 col-xl"
                >
                    <!-- Hours -->
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'members',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <!-- Title -->
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("users.users") }}
                                        </h6>

                                        <!-- Heading -->

                                        <!-- <span class="h2 mb-0">{{
                                            getActiveLocation.UsersCount  ||
                                            0
                                        }}</span> -->
                                        <span class="h2 mb-0">{{
                                            locationUsersList.length  ||
                                            0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <!-- Icon -->
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-user
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                        </b-link>
                    </div>
                </div>

                <div
                    class="col-12 col-lg-6 col-xl"
                >
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'doors',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("doors.doors") }}
                                        </h6>
                                        <span class="h2 mb-0">{{
                                            getActiveLocation.DevicesCount || 0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-door
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </b-link>
                    </div>
                </div>

                <div
                    class="col-12 col-lg-6 col-xl"
                >
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'keys',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("keys.keys") }}
                                        </h6>
                                        <span class="h2 mb-0">{{
                                            getActiveLocation.KeysCount || 0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-key
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </b-link>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div
                    class="col-12 col-lg-6 col-xl"
                >
                    <!-- Hours -->
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'members',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <!-- Title -->
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("users.users") }}
                                        </h6>

                                        <!-- Heading -->

                                        <!-- <span class="h2 mb-0">{{
                                            getActiveLocation.UsersCount  ||
                                            0
                                        }}</span> -->
                                        <span class="h2 mb-0">{{
                                            locationUsersList.length  ||
                                            0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <!-- Icon -->
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-user
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                        </b-link>
                    </div>
                </div>

                <div
                    class="col-12 col-lg-6 col-xl"
                >
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'doors',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("doors.doors") }}
                                        </h6>
                                        <span class="h2 mb-0">{{
                                            getActiveLocation.DevicesCount || 0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-door
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </b-link>
                    </div>
                </div>

                <div
                    class="col-12 col-lg-6 col-xl"
                >
                    <div class="card">
                        <b-link
                            :to="{
                                name: 'keys',
                                params: {
                                    locationId: getActiveLocation.Id,
                                    companyId: getActiveLocation.CompanyId,
                                },
                            }"
                        >
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6
                                            class="
                                                text-uppercase text-muted
                                                mb-2
                                            "
                                        >
                                            {{ $t("keys.keys") }}
                                        </h6>
                                        <span class="h2 mb-0">{{
                                            getActiveLocation.KeysCount || 0
                                        }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <span
                                            class="
                                                h2
                                                fi
                                                flaticon-key
                                                text-muted
                                                mb-0
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </b-link>
                    </div>
                </div>
            </div>
<!-- v-if="$can('viewActivity', getActiveLocation)" v-if="activity.length && !loading"-->
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col">
                                    <!-- Title -->
                                    <h4 class="card-header-title">
                                        {{ $t("activity.title") }}
                                    </h4>
                                    <small class="text-muted">
                                        {{ $t("activity.latest_activity") }}
                                    </small>
                                </div>
                                <div class="col-auto">
                                    <!-- Button -->
                                    <b-link
                                        v-if="admin || roleFlag"
                                        :to="{
                                            name: 'activity',
                                            params: {
                                                locationId:
                                                    getActiveLocation.Id,
                                                companyId:
                                                    getActiveLocation.CompanyId,
                                            },
                                        }"
                                        class="small"
                                        >{{ $t("activity.all") }}</b-link
                                    >
                                </div>
                            </div>
                            <!-- / .row -->
                        </div>
                        <!-- <zs-activity-timeline
                            v-if="activity.length && !loading"
                            :activityLog="activity"
                        /> -->
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div
                                    style="width: 100px;
                                        height: 100px;
                                        position: absolute;
                                        top: 70px;
                                        left: 0;
                                        right: 0;
                                        margin: auto;"
                                    v-if="loading"
                                >
                                    <b-spinner :label="$t('common.loading')"></b-spinner>
                                </div>
                                <zs-activity-location-timeline v-if="activity.length && !loading" :activityLog="paginatedLocationActivityList" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <zs-image-upload-modal @imageUploaded="updateLocationImage" />
    </div>
    <div v-else>
        <div class="card-body">
            <div class="row align-items-center">
                <b-spinner :label="$t('common.loading')"></b-spinner>
            </div>
        </div>
    </div>
</template>

<script>
import LocationTimeline from "@/components/activity/LocationTimeline";
import ImageUploadModal from "@/components/modals/ImageUpload";
import activityMixin from "@/mixins/activityMixin";
import modalMixin from "@/mixins/modalMixin";

import { mapGetters } from "vuex";
import {
    M_LOCATION_FORM,
    M_LOCATION_DELETE,
    M_IMAGE_UPLOAD,
} from "@/components/modals/types";
import {
    definedKeyRolesIdsArray,
    ROLE_COMPANY_MANAGER,
    ROLE_COMPANY_OWNER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_MANAGER,
    ROLE_LOCATION_MANAGER
} from "@/acl/roles";
import {
    LOCATION_GET,
    LOCATION_PATCH,
} from "@/store/types/locations";
import {
    LOCATION_MEMBERS_GET,
} from "@/store/types/members";
import {
    CHECK_ADMIN_USER
  } from "@/store/types/admin";
import { ACTIVITY_LOG_LOCATION, IMAGE_TYPE_LOCATION } from "@/handlers/const";
import { EventBus } from "@/main.js"
import { USER_GET } from "@/store/types/user";
import { sleep } from "@/store/modules/global";

export default {
    mixins: [activityMixin, modalMixin],
    data() {
        return {
            activity: [],
            newLocationList: [],
            perLocationPage: 10,
            currentLocationPage: 1,
            admin: false,
            userRole: "",
            userId: null,
            roleFlag: false,
        };
    },
    computed: {
        ...mapGetters(["getActiveLocation", "getActiveCompany", "getLocationMembersSorted", "getSuperAdmin", "getUserRoles", "getUser", "getActiveUserRole"]),
        locationUsersList() {
            return this.getLocationMembersSorted;
        },
        activityLocationList(){
            this.$session.set("newLocationList", this.activity.length);
            return this.activity;
        },
        paginatedLocationActivityList() {
            // return this.initLocationActivity();
            return this.activityLocationList.slice(
                (this.currentLocationPage - 1) * this.perLocationPage,
                this.currentLocationPage * this.perLocationPage
            );
        },
    },
    async created() {
        await this.$store.dispatch(LOCATION_GET, this.$route.params.locationId);
        await this.$store
            .dispatch(LOCATION_MEMBERS_GET, this.$route.params.locationId);

        // wait for init completed
        for (let i = 0; i < 30; i++) {
            await sleep(100)
            if (this.getInitProcessing === false) {
                break
            }
        }

        this.admin = this.getAdminUser();
        this.userId = this.getUser?.Id
        if (this.getActiveUserRole == "CompanyOwner" || this.getActiveUserRole == "CompanyManager" || this.getActiveUserRole == "LocationManager" || this.getActiveUserRole == "KeyManager") {
            this.roleFlag = true
        } else {
            this.roleFlag = false
        }

        if (this.userId)
            this.loadLocationLog(this.$route.params.locationId, (this.roleFlag) ? null : this.userId);
        else
            this.loadLocationLog(this.$route.params.locationId, null);
    },
    watch: {
        getActiveUserRole(val) {
            //console.log(`getActiveUserRole: ${val}`)

            if( val == "CompanyOwner" || val == "CompanyManager" || val == "LocationManager" || val == "KeyManager"){
                this.roleFlag = true
            } else{
                this.roleFlag = false
            }
        },
    },
    methods: {
        getAdminUser(){
          this.$store.dispatch(CHECK_ADMIN_USER).then((response) => {
            if (response.data.status == true) {
              this.admin = response.data.status;
              this.$session.set("adminUser", true);
            }else{
              this.admin = false;
            }
          });
          return this.admin;
        },
        loadLocationLog(locationId, userId) {
            this.$store.dispatch(LOCATION_GET, locationId).then((response) => {
                // if (this.$can("viewActivity", response)) {
                    this.loadActivity(
                        {
                            locationId: locationId,
                            UserId: userId,
                            IgnorePagination: false,
                            page: 0,
                            limit: 10,
                        },
                        ACTIVITY_LOG_LOCATION
                    );
                // }
            });
        },
        initLocationActivity(){
            this.newLocationList =[]
            for(let i=0; i<this.perLocationPage; i++){
                this.newLocationList.push(this.activity[i])
            }
            this.$session.set("newLocationList", this.activity.length);
            return this.newLocationList;
        },
        editLocation(location) {
            this.showModal({ location: location }, M_LOCATION_FORM);
        },
        deleteLocation(location) {
            this.showModal({ location: location }, M_LOCATION_DELETE);
        },
        locationImage(location) {
            this.showModal(
                {
                    image: {
                        url:
                            location.MediumImageUrl ||
                            "/images/zesec-placeholder.png",
                        type: IMAGE_TYPE_LOCATION,
                    },
                },
                M_IMAGE_UPLOAD
            );
        },
        updateLocationImage(response) {
            this.$store
                .dispatch(LOCATION_PATCH, {
                    ...this.getActiveLocation,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                    this.$store.dispatch(
                        LOCATION_GET,
                        this.getActiveLocation.Id
                    );
                })
                .catch((error) => {});
        },
    },
    components: {
        "zs-activity-location-timeline": LocationTimeline,
        "zs-image-upload-modal": ImageUploadModal,
    },
    beforeRouteUpdate(to, from, next) {
        this.loadLocationLog(to.params.locationId);
        next();
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: hidden !important;
}
</style>
